@import "buttons";
@import "../functions";
@import "hacks";
@import "direction";

@mixin product-card-list() {
  flex-direction: row;

  .product-card__name {
    font-size: 15px;
    line-height: 20px;
    flex-grow: 0;
  }
  .product-card__rating {
    margin-top: 7px;
  }
  .product-card__description {
    color: $product-card-font-alt-color;
    font-size: 15px;
    line-height: 22px;
    margin-top: 12px;
  }
  .product-card__prices {
    margin-top: 16px;
    font-size: 18px;
  }
  .product-card__old-price {
    font-size: 14px;
  }
  .product-card__buttons {
    flex-wrap: wrap;
  }
  .product-card__addToCart {
    display: none;
  }
  .product-card__addToCart--list {
    display: block;
  }

  .product-card__addToCart {
    width: 100%;
  }
  .product-card__addToCart + * {
    @include direction {
      #{$margin-inline-start}: 0;
    }
  }
  .product-card__addToCart ~ * {
    margin-top: 8px;
  }

  .product-card__addToCart,
  .product-card__wishlist,
  .product-card__compare {
    @include btn-sm();
  }
  .product-card__availability {
    color: $product-card-font-muted-color;
    font-size: 14px;

    @include direction {
      #{$padding-inline-end}: 10px;
    }
  }

  .product-card__features-list {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;

    li {
      padding: 3px 0;

      @include direction {
        #{$padding-inline-start}: 12px;
        #{$padding-inline-end}: 0;
      }
    }

    li::before {
      top: 8px;
    }
  }

  @media (max-width: breakpoint(sm-end)) {
    flex-direction: column;

    .product-card__buttons {
      flex-wrap: nowrap;

      .btn {
        @include btn-nl();
      }
    }
    .product-card__addToCart--list {
      width: auto;

      @include direction {
        #{$margin-inline-end}: auto;
      }
    }
  }
}

@mixin product-card-grid() {
  .product-card__description {
    display: none;
  }
  .product-card__availability {
    display: none;
  }
  .product-card__features-list {
    display: none;
  }
}

@mixin product-card-grid-with-features() {
  .product-card__features-list {
    display: block;
  }
}

@mixin product-card-grid-sm() {
  .product-card__badges-list {
    top: 16px;

    @include direction {
      #{$inset-inline-start}: 16px;
    }
  }

  @media (hover: hover) {

    &:hover {
      position: relative;
      z-index: 3;

      .product-card__buttons {
        display: inline-block;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .product-card__buttons {
      display: inline-block;
      margin-left: 10px;
    }

    &:hover {
      margin-bottom: 0;

      .product-card__buttons {
        display: inline-block;
      }
    }
  }

  @include only-ie() {

    &:hover {
      position: relative;
      z-index: 3;

      .product-card__buttons {
        display: inline-block;
      }
    }
  }
}

@mixin product-card-grid-nl() {
  .product-card__buttons .btn {
    @include btn-sm();
  }

  @media (hover: hover) {

    &:hover {
      position: relative;
      z-index: 3;
      display: block;

      .product-card__buttons {
        display: inline-block;
      }
    }
  }

  @include only-ie() {
    .product-card__buttons {
      display: none;
    }

    &:hover {
      position: relative;
      z-index: 3;

      .product-card__buttons {
        display: inline-block;
      }
    }
  }
}

@mixin product-card-grid-lg() {
  .product-card__name {
    font-size: 17px;
    line-height: 23px;
  }
  .product-card__actions {
    padding-bottom: 15px;
  }
  .product-card__rating {
    margin-top: 10px;
  }
  .product-card__prices {
    margin-bottom: auto;
    font-size: 20px;
  }
}

@mixin product-card-horizontal() {
  flex-direction: row;

  .product-card__badges-list {
    top: 10px;

    @include direction {
      #{$inset-inline-start}: 10px;
    }
  }
  .product-card__badge {
    padding: 3px 5px 2px;
  }
  .product-card__name {
    overflow: hidden;
    max-height: 19px * 2;
  }
  .product-card__rating {
    white-space: nowrap;
  }
  .product-card__description {
    display: none;
  }
  .product-card__availability {
    display: none;
  }
  .product-card__buttons {
    display: none;
  }
  .product-card__features-list {
    display: none;
  }
}

@mixin product-card-horizontal-sm() {
  .product-card__rating {
    display: none;
  }
}

@mixin product-card-horizontal-lg() {
  $local-image-size: 216px;
  $local-image-margin: 16px;
  $local-image-padding: 24px;

  .product-card__name {
    font-size: 17px;
    margin-bottom: 2px;
    flex-grow: 0;
  }

  .product-card__prices {
    margin-top: 0;
    font-size: 20px;

    @include direction {
      #{$margin-inline-end}: 24px;
    }
  }
  .product-card__buttons {
    display: inline-block;
  }
}
