.b-container {
  min-height: 0;
  margin-bottom: 0 !important;
  margin-top: 60px !important;
  padding: 0 15px !important;

}
@media only screen and (max-width: 500px) {
  .dataAosRef[data-aos=fade-left] {
    transform: translate3d(16px,0,0) !important
  }
}

.zg-row {
  display: flex;
}

.zg-grid-col {
  width: 100%;
  padding: 5px 10px;

  img {
    width: 100%;
  }
}

.zg-container {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-fluid {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-lg {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-md {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-row {
  //--bs-gutter-x: .5rem;
  //--bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  //margin-top: calc(var(--bs-gutter-y) * -1);
  //margin-right: calc(var(--bs-gutter-x) * -.5);
  //margin-left: calc(var(--bs-gutter-x) * -.5);

  > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }

  > .zg-dragforborder {
    box-sizing: border-box;
    background-color: $background-color_1;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }
}

.zg-draggable {
  .zg-dragforborder {
    padding: 5px;
    transition: 0.5s all ease-in;
  }
}

.zg-col {
  flex: 1 0 0;
}

.zg-row-cols-auto {
  > * {
    flex: 0 0 auto;
    width: auto;
  }
}

.zg-row-cols-1 {
  > * {
    flex: 0 0 auto;
    width: 100%;
  }
}

.zg-row-cols-2 {
  > * {
    flex: 0 0 auto;
    width: 50%;
  }
}

.zg-row-cols-3 {
  > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}

.zg-row-cols-4 {
  > * {
    flex: 0 0 auto;
    width: 25%;
  }
}

.zg-row-cols-5 {
  > * {
    flex: 0 0 auto;
    width: 20%;
  }
}

.zg-row-cols-6 {
  > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

.zg-col-auto {
  flex: 0 0 auto;
  width: auto;
}

.zg-col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.zg-col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.zg-col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.zg-col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.zg-col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.zg-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.zg-col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.zg-col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.zg-col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.zg-col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.zg-col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.zg-col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.zg-offset-1 {
  margin-left: 8.33333333%;
}

.zg-offset-2 {
  margin-left: 16.66666667%;
}

.zg-offset-3 {
  margin-left: 25%;
}

.zg-offset-4 {
  margin-left: 33.33333333%;
}

.zg-offset-5 {
  margin-left: 41.66666667%;
}

.zg-offset-6 {
  margin-left: 50%;
}

.zg-offset-7 {
  margin-left: 58.33333333%;
}

.zg-offset-8 {
  margin-left: 66.66666667%;
}

.zg-offset-9 {
  margin-left: 75%;
}

.zg-offset-10 {
  margin-left: 83.33333333%;
}

.zg-offset-11 {
  margin-left: 91.66666667%;
}

.zg-g-0 {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.zg-gx-0 {
  --bs-gutter-x: 0;
}

.zg-gy-0 {
  --bs-gutter-y: 0;
}

.zg-g-1 {
  --bs-gutter-x: 0.25rem;
  --bs-gutter-y: 0.25rem;
}

.zg-gx-1 {
  --bs-gutter-x: 0.25rem;
}

.zg-gy-1 {
  --bs-gutter-y: 0.25rem;
}

.zg-g-2 {
  --bs-gutter-x: 0.5rem;
  --bs-gutter-y: 0.5rem;
}

.zg-gx-2 {
  --bs-gutter-x: 0.5rem;
}

.zg-gy-2 {
  --bs-gutter-y: 0.5rem;
}

.zg-g-3 {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 1rem;
}

.zg-gx-3 {
  --bs-gutter-x: 1rem;
}

.zg-gy-3 {
  --bs-gutter-y: 1rem;
}

.zg-g-4 {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
}

.zg-gx-4 {
  --bs-gutter-x: 1.5rem;
}

.zg-gy-4 {
  --bs-gutter-y: 1.5rem;
}

.zg-g-5 {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 3rem;
}

.zg-gx-5 {
  --bs-gutter-x: 3rem;
}

.zg-gy-5 {
  --bs-gutter-y: 3rem;
}

.zg-d-inline {
  display: inline !important;
}

.zg-d-inline-block {
  display: inline-block !important;
}

.zg-d-block {
  display: block !important;
}

.zg-d-grid {
  display: grid !important;
}

.zg-d-table {
  display: table !important;
}

.zg-d-table-row {
  display: table-row !important;
}

.zg-d-table-cell {
  display: table-cell !important;
}

.zg-d-flex {
  display: flex !important;
}

.zg-d-inline-flex {
  display: inline-flex !important;
}

.zg-d-none {
  display: none !important;
}

.zg-flex-fill {
  flex: 1 1 auto !important;
}

.zg-flex-row {
  flex-direction: row !important;
}

.zg-flex-column {
  flex-direction: column !important;
}

.zg-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.zg-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.zg-flex-grow-0 {
  flex-grow: 0 !important;
}

.zg-flex-grow-1 {
  flex-grow: 1 !important;
}

.zg-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.zg-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.zg-flex-wrap {
  flex-wrap: wrap !important;
}

.zg-flex-nowrap {
  flex-wrap: nowrap !important;
}

.zg-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.zg-justify-content-start {
  justify-content: flex-start !important;
}

.zg-justify-content-end {
  justify-content: flex-end !important;
}

.zg-justify-content-center {
  justify-content: center !important;
}

.zg-justify-content-between {
  justify-content: space-between !important;
}

.zg-justify-content-around {
  justify-content: space-around !important;
}

.zg-justify-content-evenly {
  justify-content: space-evenly !important;
}

.zg-algin-items-start {
  align-items: flex-start !important;
}

.zg-algin-items-end {
  align-items: flex-end !important;
}

.zg-algin-items-center {
  align-items: center !important;
}

.zg-algin-items-baseline {
  align-items: baseline !important;
}

.zg-algin-items-stretch {
  align-items: stretch !important;
}

.zg-algin-content-start {
  align-content: flex-start !important;
}

.zg-algin-content-end {
  align-content: flex-end !important;
}

.zg-algin-content-center {
  align-content: center !important;
}

.zg-algin-content-between {
  align-content: space-between !important;
}

.zg-algin-content-around {
  align-content: space-around !important;
}

.zg-algin-content-stretch {
  align-content: stretch !important;
}

.zg-algin-self-auto {
  align-self: auto !important;
}

.zg-algin-self-start {
  align-self: flex-start !important;
}

.zg-algin-self-end {
  align-self: flex-end !important;
}

.zg-algin-self-center {
  align-self: center !important;
}

.zg-algin-self-baseline {
  align-self: baseline !important;
}

.zg-algin-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .zg-container {
    max-width: 540px;
  }
  .zg-container-sm {
    max-width: 540px;
  }
  .zg-col-sm {
    flex: 1 0 0;
  }
  .zg-row-cols-sm-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .zg-row-cols-sm-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .zg-row-cols-sm-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .zg-row-cols-sm-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .zg-row-cols-sm-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .zg-row-cols-sm-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .zg-row-cols-sm-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .zg-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .zg-col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .zg-col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .zg-col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .zg-col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .zg-col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .zg-col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .zg-col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .zg-col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .zg-col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .zg-col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .zg-col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .zg-col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .zg-offset-sm-0 {
    margin-left: 0;
  }
  .zg-offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .zg-offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .zg-offset-sm-3 {
    margin-left: 25%;
  }
  .zg-offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .zg-offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .zg-offset-sm-6 {
    margin-left: 50%;
  }
  .zg-offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .zg-offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .zg-offset-sm-9 {
    margin-left: 75%;
  }
  .zg-offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .zg-offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .zg-g-sm-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .zg-gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .zg-gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .zg-g-sm-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .zg-gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .zg-gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .zg-g-sm-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .zg-gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .zg-gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .zg-g-sm-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .zg-gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .zg-gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .zg-g-sm-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .zg-gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .zg-gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .zg-g-sm-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .zg-gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .zg-gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .zg-d-sm-inline {
    display: inline !important;
  }
  .zg-d-sm-inline-block {
    display: inline-block !important;
  }
  .zg-d-sm-block {
    display: block !important;
  }
  .zg-d-sm-grid {
    display: grid !important;
  }
  .zg-d-sm-table {
    display: table !important;
  }
  .zg-d-sm-table-row {
    display: table-row !important;
  }
  .zg-d-sm-table-cell {
    display: table-cell !important;
  }
  .zg-d-sm-flex {
    display: flex !important;
  }
  .zg-d-sm-inline-flex {
    display: inline-flex !important;
  }
  .zg-d-sm-none {
    display: none !important;
  }
  .zg-flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .zg-flex-sm-row {
    flex-direction: row !important;
  }
  .zg-flex-sm-column {
    flex-direction: column !important;
  }
  .zg-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .zg-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .zg-flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .zg-flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .zg-flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .zg-flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .zg-flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .zg-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .zg-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .zg-justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .zg-justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .zg-justify-content-sm-center {
    justify-content: center !important;
  }
  .zg-justify-content-sm-between {
    justify-content: space-between !important;
  }
  .zg-justify-content-sm-around {
    justify-content: space-around !important;
  }
  .zg-justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .zg-algin-items-sm-start {
    align-items: flex-start !important;
  }
  .zg-algin-items-sm-end {
    align-items: flex-end !important;
  }
  .zg-algin-items-sm-center {
    align-items: center !important;
  }
  .zg-algin-items-sm-baseline {
    align-items: baseline !important;
  }
  .zg-algin-items-sm-stretch {
    align-items: stretch !important;
  }
  .zg-algin-content-sm-start {
    align-content: flex-start !important;
  }
  .zg-algin-content-sm-end {
    align-content: flex-end !important;
  }
  .zg-algin-content-sm-center {
    align-content: center !important;
  }
  .zg-algin-content-sm-between {
    align-content: space-between !important;
  }
  .zg-algin-content-sm-around {
    align-content: space-around !important;
  }
  .zg-algin-content-sm-stretch {
    align-content: stretch !important;
  }
  .zg-algin-self-sm-auto {
    align-self: auto !important;
  }
  .zg-algin-self-sm-start {
    align-self: flex-start !important;
  }
  .zg-algin-self-sm-end {
    align-self: flex-end !important;
  }
  .zg-algin-self-sm-center {
    align-self: center !important;
  }
  .zg-algin-self-sm-baseline {
    align-self: baseline !important;
  }
  .zg-algin-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .zg-container {
    max-width: 720px;
  }
  .zg-container-md {
    max-width: 720px;
  }
  .zg-container-sm {
    max-width: 720px;
  }
  .zg-col-md {
    flex: 1 0 0;
  }
  .zg-row-cols-md-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .zg-row-cols-md-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .zg-row-cols-md-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .zg-row-cols-md-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .zg-row-cols-md-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .zg-row-cols-md-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .zg-row-cols-md-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .zg-col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .zg-col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .zg-col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .zg-col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .zg-col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .zg-col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .zg-col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .zg-col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .zg-col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .zg-col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .zg-col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .zg-col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .zg-col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .zg-offset-md-0 {
    margin-left: 0;
  }
  .zg-offset-md-1 {
    margin-left: 8.33333333%;
  }
  .zg-offset-md-2 {
    margin-left: 16.66666667%;
  }
  .zg-offset-md-3 {
    margin-left: 25%;
  }
  .zg-offset-md-4 {
    margin-left: 33.33333333%;
  }
  .zg-offset-md-5 {
    margin-left: 41.66666667%;
  }
  .zg-offset-md-6 {
    margin-left: 50%;
  }
  .zg-offset-md-7 {
    margin-left: 58.33333333%;
  }
  .zg-offset-md-8 {
    margin-left: 66.66666667%;
  }
  .zg-offset-md-9 {
    margin-left: 75%;
  }
  .zg-offset-md-10 {
    margin-left: 83.33333333%;
  }
  .zg-offset-md-11 {
    margin-left: 91.66666667%;
  }
  .zg-g-md-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .zg-gx-md-0 {
    --bs-gutter-x: 0;
  }
  .zg-gy-md-0 {
    --bs-gutter-y: 0;
  }
  .zg-g-md-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .zg-gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .zg-gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .zg-g-md-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .zg-gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .zg-gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .zg-g-md-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .zg-gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .zg-gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .zg-g-md-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .zg-gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .zg-gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .zg-g-md-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .zg-gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .zg-gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .zg-d-md-inline {
    display: inline !important;
  }
  .zg-d-md-inline-block {
    display: inline-block !important;
  }
  .zg-d-md-block {
    display: block !important;
  }
  .zg-d-md-grid {
    display: grid !important;
  }
  .zg-d-md-table {
    display: table !important;
  }
  .zg-d-md-table-row {
    display: table-row !important;
  }
  .zg-d-md-table-cell {
    display: table-cell !important;
  }
  .zg-d-md-flex {
    display: flex !important;
  }
  .zg-d-md-inline-flex {
    display: inline-flex !important;
  }
  .zg-d-md-none {
    display: none !important;
  }
  .zg-flex-md-fill {
    flex: 1 1 auto !important;
  }
  .zg-flex-md-row {
    flex-direction: row !important;
  }
  .zg-flex-md-column {
    flex-direction: column !important;
  }
  .zg-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .zg-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .zg-flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .zg-flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .zg-flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .zg-flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .zg-flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .zg-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .zg-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .zg-justify-content-md-start {
    justify-content: flex-start !important;
  }
  .zg-justify-content-md-end {
    justify-content: flex-end !important;
  }
  .zg-justify-content-md-center {
    justify-content: center !important;
  }
  .zg-justify-content-md-between {
    justify-content: space-between !important;
  }
  .zg-justify-content-md-around {
    justify-content: space-around !important;
  }
  .zg-justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .zg-algin-items-md-start {
    align-items: flex-start !important;
  }
  .zg-algin-items-md-end {
    align-items: flex-end !important;
  }
  .zg-algin-items-md-center {
    align-items: center !important;
  }
  .zg-algin-items-md-baseline {
    align-items: baseline !important;
  }
  .zg-algin-items-md-stretch {
    align-items: stretch !important;
  }
  .zg-algin-content-md-start {
    align-content: flex-start !important;
  }
  .zg-algin-content-md-end {
    align-content: flex-end !important;
  }
  .zg-algin-content-md-center {
    align-content: center !important;
  }
  .zg-algin-content-md-between {
    align-content: space-between !important;
  }
  .zg-algin-content-md-around {
    align-content: space-around !important;
  }
  .zg-algin-content-md-stretch {
    align-content: stretch !important;
  }
  .zg-algin-self-md-auto {
    align-self: auto !important;
  }
  .zg-algin-self-md-start {
    align-self: flex-start !important;
  }
  .zg-algin-self-md-end {
    align-self: flex-end !important;
  }
  .zg-algin-self-md-center {
    align-self: center !important;
  }
  .zg-algin-self-md-baseline {
    align-self: baseline !important;
  }
  .zg-algin-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .zg-container {
    max-width: 960px;
  }
  .zg-container-lg {
    max-width: 960px;
  }
  .zg-container-md {
    max-width: 960px;
  }
  .zg-container-sm {
    max-width: 960px;
  }
  .zg-col-lg {
    flex: 1 0 0;
  }
  .zg-row-cols-lg-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .zg-row-cols-lg-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .zg-row-cols-lg-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .zg-row-cols-lg-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .zg-row-cols-lg-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .zg-row-cols-lg-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .zg-row-cols-lg-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .zg-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .zg-col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .zg-col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .zg-col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .zg-col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .zg-col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .zg-col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .zg-col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .zg-col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .zg-col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .zg-col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .zg-col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .zg-col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .zg-offset-lg-0 {
    margin-left: 0;
  }
  .zg-offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .zg-offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .zg-offset-lg-3 {
    margin-left: 25%;
  }
  .zg-offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .zg-offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .zg-offset-lg-6 {
    margin-left: 50%;
  }
  .zg-offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .zg-offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .zg-offset-lg-9 {
    margin-left: 75%;
  }
  .zg-offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .zg-offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .zg-g-lg-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .zg-gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .zg-gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .zg-g-lg-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .zg-gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .zg-gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .zg-g-lg-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .zg-gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .zg-gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .zg-g-lg-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .zg-gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .zg-gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .zg-g-lg-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .zg-gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .zg-gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .zg-g-lg-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .zg-gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .zg-gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .zg-d-lg-inline {
    display: inline !important;
  }
  .zg-d-lg-inline-block {
    display: inline-block !important;
  }
  .zg-d-lg-block {
    display: block !important;
  }
  .zg-d-lg-grid {
    display: grid !important;
  }
  .zg-d-lg-table {
    display: table !important;
  }
  .zg-d-lg-table-row {
    display: table-row !important;
  }
  .zg-d-lg-table-cell {
    display: table-cell !important;
  }
  .zg-d-lg-flex {
    display: flex !important;
  }
  .zg-d-lg-inline-flex {
    display: inline-flex !important;
  }
  .zg-d-lg-none {
    display: none !important;
  }
  .zg-flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .zg-flex-lg-row {
    flex-direction: row !important;
  }
  .zg-flex-lg-column {
    flex-direction: column !important;
  }
  .zg-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .zg-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .zg-flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .zg-flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .zg-flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .zg-flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .zg-flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .zg-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .zg-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .zg-justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .zg-justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .zg-justify-content-lg-center {
    justify-content: center !important;
  }
  .zg-justify-content-lg-between {
    justify-content: space-between !important;
  }
  .zg-justify-content-lg-around {
    justify-content: space-around !important;
  }
  .zg-justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .zg-algin-items-lg-start {
    align-items: flex-start !important;
  }
  .zg-algin-items-lg-end {
    align-items: flex-end !important;
  }
  .zg-algin-items-lg-center {
    align-items: center !important;
  }
  .zg-algin-items-lg-baseline {
    align-items: baseline !important;
  }
  .zg-algin-items-lg-stretch {
    align-items: stretch !important;
  }
  .zg-algin-content-lg-start {
    align-content: flex-start !important;
  }
  .zg-algin-content-lg-end {
    align-content: flex-end !important;
  }
  .zg-algin-content-lg-center {
    align-content: center !important;
  }
  .zg-algin-content-lg-between {
    align-content: space-between !important;
  }
  .zg-algin-content-lg-around {
    align-content: space-around !important;
  }
  .zg-algin-content-lg-stretch {
    align-content: stretch !important;
  }
  .zg-algin-self-lg-auto {
    align-self: auto !important;
  }
  .zg-algin-self-lg-start {
    align-self: flex-start !important;
  }
  .zg-algin-self-lg-end {
    align-self: flex-end !important;
  }
  .zg-algin-self-lg-center {
    align-self: center !important;
  }
  .zg-algin-self-lg-baseline {
    align-self: baseline !important;
  }
  .zg-algin-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .zg-container {
    max-width: 1140px;
  }
  .zg-container-lg {
    max-width: 1140px;
  }
  .zg-container-md {
    max-width: 1140px;
  }
  .zg-container-sm {
    max-width: 1140px;
  }
  .zg-container-xl {
    max-width: 1140px;
  }
  .zg-col-xl {
    flex: 1 0 0;
  }
  .zg-row-cols-xl-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .zg-row-cols-xl-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .zg-row-cols-xl-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .zg-row-cols-xl-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .zg-row-cols-xl-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .zg-row-cols-xl-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .zg-row-cols-xl-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .zg-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .zg-col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .zg-col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .zg-col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .zg-col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .zg-col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .zg-col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .zg-col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .zg-col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .zg-col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .zg-col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .zg-col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .zg-col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .zg-offset-xl-0 {
    margin-left: 0;
  }
  .zg-offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .zg-offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .zg-offset-xl-3 {
    margin-left: 25%;
  }
  .zg-offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .zg-offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .zg-offset-xl-6 {
    margin-left: 50%;
  }
  .zg-offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .zg-offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .zg-offset-xl-9 {
    margin-left: 75%;
  }
  .zg-offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .zg-offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .zg-g-xl-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .zg-gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .zg-gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .zg-g-xl-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .zg-gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .zg-gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .zg-g-xl-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .zg-gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .zg-gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .zg-g-xl-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .zg-gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .zg-gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .zg-g-xl-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .zg-gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .zg-gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .zg-g-xl-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .zg-gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .zg-gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .zg-d-xl-inline {
    display: inline !important;
  }
  .zg-d-xl-inline-block {
    display: inline-block !important;
  }
  .zg-d-xl-block {
    display: block !important;
  }
  .zg-d-xl-grid {
    display: grid !important;
  }
  .zg-d-xl-table {
    display: table !important;
  }
  .zg-d-xl-table-row {
    display: table-row !important;
  }
  .zg-d-xl-table-cell {
    display: table-cell !important;
  }
  .zg-d-xl-flex {
    display: flex !important;
  }
  .zg-d-xl-inline-flex {
    display: inline-flex !important;
  }
  .zg-d-xl-none {
    display: none !important;
  }
  .zg-flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .zg-flex-xl-row {
    flex-direction: row !important;
  }
  .zg-flex-xl-column {
    flex-direction: column !important;
  }
  .zg-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .zg-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .zg-flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .zg-flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .zg-flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .zg-flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .zg-flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .zg-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .zg-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .zg-justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .zg-justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .zg-justify-content-xl-center {
    justify-content: center !important;
  }
  .zg-justify-content-xl-between {
    justify-content: space-between !important;
  }
  .zg-justify-content-xl-around {
    justify-content: space-around !important;
  }
  .zg-justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .zg-algin-items-xl-start {
    align-items: flex-start !important;
  }
}

@media (min-width: 1400px) {
  .zg-container {
    max-width: 1320px;
  }
  .zg-container-lg {
    max-width: 1320px;
  }
  .zg-container-md {
    max-width: 1320px;
  }
  .zg-container-sm {
    max-width: 1320px;
  }
  .zg-container-xl {
    max-width: 1320px;
  }
  .zg-container-xxl {
    max-width: 1320px;
  }
  .zg-col-xxl {
    flex: 1 0 0;
  }
  .zg-row-cols-xxl-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .zg-row-cols-xxl-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .zg-row-cols-xxl-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .zg-row-cols-xxl-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .zg-row-cols-xxl-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .zg-row-cols-xxl-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .zg-row-cols-xxl-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .zg-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .zg-col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .zg-col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .zg-col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .zg-col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .zg-col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .zg-col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .zg-col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .zg-col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .zg-col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .zg-col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .zg-col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .zg-col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .zg-offset-xxl-0 {
    margin-left: 0;
  }
  .zg-offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .zg-offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .zg-offset-xxl-3 {
    margin-left: 25%;
  }
  .zg-offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .zg-offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .zg-offset-xxl-6 {
    margin-left: 50%;
  }
  .zg-offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .zg-offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .zg-offset-xxl-9 {
    margin-left: 75%;
  }
  .zg-offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .zg-offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .zg-g-xxl-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .zg-gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .zg-gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .zg-g-xxl-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .zg-gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .zg-gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .zg-g-xxl-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .zg-gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .zg-gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .zg-g-xxl-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .zg-gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .zg-gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .zg-g-xxl-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .zg-gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .zg-gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .zg-g-xxl-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .zg-gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .zg-gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .zg-d-xxl-inline {
    display: inline !important;
  }
  .zg-d-xxl-inline-block {
    display: inline-block !important;
  }
  .zg-d-xxl-block {
    display: block !important;
  }
  .zg-d-xxl-grid {
    display: grid !important;
  }
  .zg-d-xxl-table {
    display: table !important;
  }
  .zg-d-xxl-table-row {
    display: table-row !important;
  }
  .zg-d-xxl-table-cell {
    display: table-cell !important;
  }
  .zg-d-xxl-flex {
    display: flex !important;
  }
  .zg-d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .zg-d-xxl-none {
    display: none !important;
  }
  .zg-flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .zg-flex-xxl-row {
    flex-direction: row !important;
  }
  .zg-flex-xxl-column {
    flex-direction: column !important;
  }
  .zg-flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .zg-flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .zg-flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .zg-flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .zg-flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .zg-flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .zg-flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .zg-flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .zg-flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .zg-justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .zg-justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .zg-justify-content-xxl-center {
    justify-content: center !important;
  }
  .zg-justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .zg-justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .zg-justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .zg-algin-items-xxl-start {
    align-items: flex-start !important;
  }
  .zg-algin-items-xxl-end {
    align-items: flex-end !important;
  }
  .zg-algin-items-xxl-center {
    align-items: center !important;
  }
  .zg-algin-items-xxl-baseline {
    align-items: baseline !important;
  }
  .zg-algin-items-xxl-stretch {
    align-items: stretch !important;
  }
  .zg-algin-content-xxl-start {
    align-content: flex-start !important;
  }
  .zg-algin-content-xxl-end {
    align-content: flex-end !important;
  }
  .zg-algin-content-xxl-center {
    align-content: center !important;
  }
  .zg-algin-content-xxl-between {
    align-content: space-between !important;
  }
  .zg-algin-content-xxl-around {
    align-content: space-around !important;
  }
  .zg-algin-content-xxl-stretch {
    align-content: stretch !important;
  }
  .zg-algin-self-xxl-auto {
    align-self: auto !important;
  }
  .zg-algin-self-xxl-start {
    align-self: flex-start !important;
  }
  .zg-algin-self-xxl-end {
    align-self: flex-end !important;
  }
  .zg-algin-self-xxl-center {
    align-self: center !important;
  }
  .zg-algin-self-xxl-baseline {
    align-self: baseline !important;
  }
  .zg-algin-self-xxl-stretch {
    align-self: stretch !important;
  }
}


.zg-grid-column-container {
  padding: 25px 0;
  border: 2px dashed #d5dadf;
  position: relative;

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .zg-column-block-for-grid {
    text-align: center;

    .zg-flex-grid-column {
      display: flex;
      flex-wrap: wrap;
      max-width: 550px;
      justify-content: center;
      align-items: center;
      grid-gap: 5px;
      margin: 0 auto;

      svg {
        height: 45px;
        width: 80px;
        cursor: pointer;

      }

    }
  }
}

/************** Global styling for all pages ***********/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a, a:hover, a:focus {
  transition: 0.5s;
  outline: none;
}

a {
  color: $body-font-color;
  text-decoration: none;
}

button {
  border: none !important;
}

hr {
  border: none;
  height: 1px;
  background: #ebebeb;
  margin: 18px 0;
}

select {
  background-color: transparent;
}

select:focus-visible {
  outline: none;
}

.container {
  padding: 0 15px;
  margin: auto !important;
  max-width: 1390px;
  width: 100%;
  height: 100%;
}

.d-flex {
  display: flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid black;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.truncateF16 {
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.cookie_body {
  max-width: 441px !important;
  width: 100% !important;
  background-color: #ffffff !important;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px !important;
  bottom: 20px !important;
  left: 20px !important;
  z-index: 9999999999 !important;
  display: none;
}

.cookie_body_elements {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  div {
    > h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
      color: #161616;
      margin-bottom: 5px;
    }

    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      margin-bottom: 0 !important;
    }
  }
}

.cookie_button_wrapper {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.cookie_button {
  color: #ffffff !important;
  background: $main-theme-color-light !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  padding: 10px 20px !important;
  border-style: none !important;

  &:hover {
    background-color: $dark-color !important; // $main-theme-color-hover
    color: $dark-opposite-color !important
  }
}

.cookie_image {
  > svg {
    > path {
      fill: $main-theme-color-light !important;
    }
  }
}

.cookie_content {
  margin: 15px 15px 0 !important;
}

@media (max-width: 481px) {
  .cookie_body {
    left: 0 !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: $main-theme-color-light;
}

.fieldset-params-fms {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .contact_field {
    width: calc((100% - 20px) / 3);
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  .fieldset-params-fms {
    margin-bottom: 10px;
    flex-direction: column;

    input {
      padding: 16.5px 14px;
    }
  }
}

.chek-fms {
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: 50px;
  overflow: hidden;

  svg {
    height: 50px;
    min-width: 50px;
    margin-right: 16px;
  }
}

.modal-body {
  span {
    position: relative !important;
  }

  img {
    position: unset !important;
    height: auto !important;
    width: auto !important;
    max-height: unset !important;
    max-width: unset !important;
    min-height: unset !important;
    min-width: unset !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 928px) {
  .products-list__body {
    .products-list__item {
      margin-right: auto;
    }

    .products-list__item:nth-child(3) {
      margin-right: unset;
    }
  }
}

.contact-with-us {
  padding: 1.5rem !important;
}

.contact-customhtml-fms {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 100px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .contact-customhtml-fms {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .contact-with-us {
    .inner-addToCart {
      width: 100%;
    }
  }
}

.text-danger {
  font-size: 12px;
  color: red;
}

.text-success {
  font-size: 12px;
  color: #2ba847;
}
.error-page-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .error-page-container_section {
    width: 60%;
    text-align: center;

    h3 {
      font-size: 23px !important;
    }

    p {
      span {
        cursor: pointer;
      }
    }
  }
}

///////////// FIXME: in future transfer this part to shared.module.scss or in scss file for product gallery

.carousel-parent {
  .slick-track {
    margin-left: 0 !important;
  }
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
}

.faild-toast-fms {
  display: flex;
  justify-content: center;
  align-items: center;
}

.faild-toast-fms,
.chek-fms {
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: 50px;
  overflow: hidden;

  svg {
    height: 50px;
    min-width: 50px;
    margin-right: 16px;
  }
}

.rotT_fms {
  transform: rotate(180deg);
}