@import "../mixins/direction";

.form-control:valid,
.form-control.is-valid,
.form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

@mixin local-select-define-size($size: "") {
  @if ($size !="") {
    $size: "-"+ $size;
  }

  select.form-control#{$size},
  select.form-control#{$size}:valid,
  select.form-control#{$size}.is-valid,
  select.form-control#{$size}:invalid,
  select.form-control#{$size}.is-invalid {
    @content;
  }
}

select.form-control {
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }
}

@include local-select-define-size() {
  background-repeat: no-repeat;
  background-size: 5px 10px;

  @include direction {
    background-position: $inline-end 0.75rem center;
    #{$padding-inline-end}: calc(0.75rem * 2 + 5px);
  }
}

@include local-select-define-size("sm") {
  @include direction {
    background-position: $inline-end 0.5rem center;
    #{$padding-inline-end}: calc(0.5rem * 2 + 5px);
  }
}

@include local-select-define-size("lg") {
  @include direction {
    background-position: $inline-end 1rem center;
    #{$padding-inline-end}: calc(1rem * 2 + 5px);
  }
}
