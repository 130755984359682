@import "../mixins/direction";

.not-found__con {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 450px 270px;

  .not-found__con__image {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;

    span {
      position: relative !important;
    }

    & > div {
      position: static !important;
      width: 100% !important;
      height: 100% !important;
    }

    img {
      position: static !important;
      width: 100% !important;
      object-fit: contain;
    }

  }

  .not-found__con__text {
    margin: 0 auto;
    position: relative;

    .text-con {
      display: flex;
      flex-direction: column;
      align-items: center;

      .not-found__con__text_p {
        font-family: $body-font-family;
        font-size: 60px;
        font-weight: 400;
        line-height: 82px;
        color: #33475B;
        text-align: center;
      }

      .not-found__con__small_p {
        font-family: $body-font-family;
        font-size: 20px;
        font-weight: 400;
        line-height: 27px;
        text-align: center;
      }

      a {
        font-family: $body-font-family;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: center;
        color: #0d47a1;
        text-decoration-line: underline;

        &:hover {
          color: #000C66;
        }

        &:active {
          color: $light-opposite-color;
        }
      }
    }
  }
}

@media (max-width: breakpoint(md-end)) {
  .not-found__con__text {
    .text-con {
    }
  }
}

@media (max-width: breakpoint(md-start)) {
  .not-found__con {
    grid-template-rows: 350px 150px;

    .not-found__con__text {
      position: static !important;

      .text-con {
        position: static !important;

        .not-found__con__text_p {
          font-size: 24px;
          font-weight: 400;
          line-height: 33px;

        }

        .not-found__con__small_p {
          font-size: 20px;
          font-weight: 400;
          line-height: 27px;
          white-space: pre-wrap;
        }
      }
    }
  }
}

@media (max-width: breakpoint(xs-midd)) {
  .not-found__con {
    grid-template-rows: 250px 180px;

    .not-found__con__text {
      width: 100%;

      .text-con {
        .not-found__con__text_p {
          width: 100%;
        }
      }
    }
  }
}
