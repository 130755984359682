.rounde-pills {
  color: white;
  border-radius: $button-border-radius;
}

.action-fms {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
