@import '../mixins/direction';

.alert {
  border: none;
  border-radius: $button-border-radius;
  font-size: 15px;
  line-height: 20px;
  padding: .75rem 1rem;

  a {
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    color: $body-font-color;
    background: $alert-link-default-bg;
    border-radius: $button-border-radius;
    padding: 0 3px;

    &:hover {
      background: $alert-link-hover-bg;
    }

    &:active {
      background: $alert-link-active-bg;
    }
  }
}

.alert-success {
  z-index: 9999;
}

.alert-danger {
  background: none;
  color: red;
  font-size: 12px;
  padding: 0;
  margin: 0 !important;
}

@each $name, $scheme in $alerts {
  .alert-#{$name} {
    background: map_get($scheme, main);
    color: map_get($scheme, opposite);
  }
}
