@import '../mixins/direction';

.comment {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .comment-field {
    height: 80px !important;
    resize: none;
    border-radius: 9px;
    border-color: rgba(0, 0, 0, 0.23);
  }
}
