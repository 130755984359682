.SuspendMain {
  width: 100%;
  height: 950px;
  background-image: url("../../svg/suspendBack.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Suspend {
  padding-left: 15px !important;
  padding-right: 15px !important;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  > h1 {
    font-family: $body-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 68px;
    text-align: center;
    color: #161616;
  }

  > h3,
  h5 {
    font-family: $body-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #787878;
  }

  a {
    font-family: $body-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ff5319;

    &:hover {
      color: #00254a;
    }
  }
}

.Suspend {
  h3 {
    margin-top: 10px;
  }
}

@media (max-width: 745px) {
  .SuspendMain {
    background-image: url("../../svg/suspendBackTablet.svg");
  }
}

@media screen and (min-width: 720px) and (max-width: 1280px) {
  .SuspendImage {
    div {
      div {
        max-width: 80%;
      }
    }
  }
}

@media (max-width: 745px) {
  .SuspendMain {
    background-image: url("../../svg/suspendBackTablet.svg");
  }
}

@media screen and (min-width: 380px) and (max-width: 500px) {
  .SuspendMain {
    background-image: url("../../svg/suspendBackMobile.svg");
  }

  .SuspendImage {
    max-width: 228px;
    max-height: 204px;
  }

  .Suspend {
    > h1 {
      font-size: 20px;
      line-height: 45px;
    }

    > h3,
    h5 {
      font-size: 14px;
      line-height: 21px;
    }

    a {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 380px) {
  .Suspend {
    > h1 {
      font-size: 20px;
      line-height: 45px;
    }
  }
}
